import React from 'react';
import { Script } from 'gatsby'
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

import 'utils/styles/global.scss';
import 'utils/styles/tricks.scss';

import { QueryClient, QueryClientProvider } from 'react-query';

import SideBarContextProvider from 'context/sidebarContextWrapper';
import SearchContextProvider from 'context/searchContextWrapper';
import NotificationContextProvider from 'context/notificationContextWrapper'

import { 
  isVisible, 
  showDefault,
  headLine,
  bannerLink,
  bannerProduct, 
  bannerProductHeadline, 
  bannerProductLink 
} from 'config/announcement';
import AnnouncementBanner from 'components/AnnouncementBanner/AnnouncementBanner.component';



export const wrapRootElement = ({ element }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
        <SideBarContextProvider>
          <SearchContextProvider>
            <NotificationContextProvider>
              {element}
            </NotificationContextProvider>
          </SearchContextProvider>
        </SideBarContextProvider>
    </QueryClientProvider>
  );
};


export const wrapPageElement = ({ element, props }) => {
  const product = props.path?.split('/')[1] || 'fusion'
  const matchedProduct = product === bannerProduct
  return (
    <>
      <a className="skip-link" href="#main-content">Skip to main content</a>
      { isVisible && showDefault && !matchedProduct ? <AnnouncementBanner headLine={headLine} bannerLink={bannerLink} /> : null}
      { isVisible && matchedProduct ? <AnnouncementBanner headLine={bannerProductHeadline} bannerLink={bannerProductLink} /> : null}
      { element }
      <Script
        key='userback-1'
        src='https://static.userback.io/widget/v1.js'
        strategy="idle"
      />

      <Script 
        key='userback-2'
        strategy="idle"
      >
        {`
            window.Userback = window.Userback || {};
            window.Userback.access_token = "${process.env.GATSBY_USERBACK_TOKEN_2}"
        `}
      </Script>

      <Script
        id="lw-ui-lib"
        type="module"
        key="sdlkvjE8k"
        src="https://storage.googleapis.com/sb-ui/springboard.esm.js"
        strategy="idle"
        beacon='{
          "api": {
            "apiUrl": "https://e08cd6ca-dbd2-4274-9229-5abdb49862e5.applications.lucidworks.com",
            "embedToken": "LckdQ9StwAFL5M4HqEW80vBOV2NyCMB7"
          },
          "attributes": {
            "productPath": ".searchResults .resultsItem, .headerSearch .dropdown-item-grid",
            "bulkSignalsInterval": 5000
          },
          "fields": {
            "productId": "a @href",
            "title": "h3 span @text, a @text",
            "uri": "a @href"
          },
          "query": {
            "queryParam": "q",
            "delayForTrackQueryUpdate": "1500"
          },
          "click": {
          }
        }'
        onLoad={() => console.log('sb-ui loaded')} // eslint-disable-line no-console
      />
    </>
  )
}
