import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby'
import CloseIcon from 'images/icons/x-icon.inline.svg';
import {bannerWrap, headline, link, bannerBtnClose, arrow} from './announcementBanner.module.scss'


 
const AnnouncementBanner = ({ headLine, bannerLink }) => {

  const [showBanner, setShowBanner] = useState(true)

  const handleBannerVisibility = () => {
    setShowBanner(false)
  }

    return (
        <>
            {showBanner ?
                <aside className={`${bannerWrap} announcement`}>
                    <div>
                        <p className={headline && link}>
                            <span className="underline">
                                <Link to={bannerLink} style={{color: '#fff'}} onClick={() => handleBannerVisibility(false) }>
                                    {headLine}
                                </Link>
                            </span>
                            <span className={arrow}>→</span>
                        </p>
                    </div>
                        <button type="button" aria-label="close banner" className={bannerBtnClose} onClick={() => handleBannerVisibility(false) }><CloseIcon/></button>
        </aside>  : <></>}
        </> 
    )
}

AnnouncementBanner.propTypes = {
    headLine: PropTypes.string.isRequired,
    bannerLink: PropTypes.string.isRequired
  };


export default AnnouncementBanner 