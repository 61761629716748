exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-to-js": () => import("./../../../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search/[...].js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-api-page-js": () => import("./../../../src/templates/api-page.js" /* webpackChunkName: "component---src-templates-api-page-js" */),
  "component---src-templates-connectors-page-js": () => import("./../../../src/templates/connectors-page.js" /* webpackChunkName: "component---src-templates-connectors-page-js" */),
  "component---src-templates-doc-page-js": () => import("./../../../src/templates/doc-page.js" /* webpackChunkName: "component---src-templates-doc-page-js" */),
  "component---src-templates-index-pipelines-page-js": () => import("./../../../src/templates/index-pipelines-page.js" /* webpackChunkName: "component---src-templates-index-pipelines-page-js" */),
  "component---src-templates-jobs-page-js": () => import("./../../../src/templates/jobs-page.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-parsers-page-js": () => import("./../../../src/templates/parsers-page.js" /* webpackChunkName: "component---src-templates-parsers-page-js" */),
  "component---src-templates-query-pipelines-page-js": () => import("./../../../src/templates/query-pipelines-page.js" /* webpackChunkName: "component---src-templates-query-pipelines-page-js" */)
}

