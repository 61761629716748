import React, { useReducer } from 'react';

export const NotificationContext = React.createContext();
export const NotificationDispatchContext = React.createContext();

export const SET_SEEN_COUNT = 'SET_SEEN_COUNT'
export const SET_SHOW_NOTIFICATION = 'SET_SHOW_NOTIFICATION'
export const SET_IS_OPEN = 'SET_IS_OPEN'

export const initialNotificationState = {
  unSeenCount: 0,
  showNotification: false,
  isOpen: false
};

function reducer(state, action) {
  switch (action.type) {
    case SET_SEEN_COUNT: {
      return {
        ...state,
        unSeenCount: action.payload,
      };
    }
    case SET_SHOW_NOTIFICATION: {
      return {
        ...state,
        showNotification: action.payload
      }
    }
    case SET_IS_OPEN: {
      return{
        ...state,
        isOpen: action.payload
      }
    }
    default:
      throw new Error('Bad Action Type');
  }
}

// eslint-disable-next-line react/prop-types
const NotificationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialNotificationState);
  return (
    <NotificationDispatchContext.Provider value={dispatch}>
      <NotificationContext.Provider value={state}>{children}</NotificationContext.Provider>
    </NotificationDispatchContext.Provider>
  );
};

export default NotificationContextProvider;
