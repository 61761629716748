const semverGte = require('semver/functions/gte')
const { fusionPersonas } = require('./personaItems');
const { fusionObjectives } = require('./objectiveItems')

const FUSION_TAG = 'fusion';
const SMART_ANSWERS_TAG = 'smart-answers';
const PREDICTIVE_MERCHANDISER_TAG = 'predictive-merchandiser';
const EXPERIENCE_OPTIMIZER_TAG = 'experience-optimizer';
const APP_STUDIO_TAG = 'app-studio';
const FUSION_SERVER_TAG = 'fusion-server';
const FUSION_AI_TAG = 'fusion-ai';
const FUSION_CONNECTORS_TAG = 'fusion-connectors';
const OTHER_PRODUCTS_TAG = 'other-products';

const allVersions = ['4.0', '4.1', '5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'];
const fusion4Tags = [FUSION_SERVER_TAG, FUSION_AI_TAG, APP_STUDIO_TAG]

const productItems = [
  {
    name: 'Fusion',
    link: '/fusion/$version/6764/fusion',
    alternateLink: '/fusion/5.4/6764/fusion/',
    description: 'Fusion 5 grants organizations the autonomy of self-hosted AI-powered data discovery and search applications, leveraging a modern, containerized, and cloud-native architecture. This empowers effortless ingestion and indexing of diverse data sources, scaling to handle billions of records.',
    tag: FUSION_TAG,
    icon: '/assets/icons/product-Server.svg',
    versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
    searchable: true,
    isHomeGrid: true,
    subMenu: 'Fusion 5',
    personas: fusionPersonas,
    objectives: fusionObjectives,
    highlight: [
      { 
        title: 'Fusion REST APIs',
        link: '/fusion/5.10/424/fusion-rest-ap-is?ref=homepage',
        icon: 'dev'
      },
      {
        title: 'Connectors SDK',
        link: '/fusion/5.10/15/connectors-sdk?ref=homepage',
        icon: 'connector'
      },
      {
        title: 'Release notes',
        link: '/fusion/5.10/97/release-notes?ref=homepage',
        icon: 'release'
      },
      {
        title: 'How-to guides',
        link: '/how-to?q=*%3A*&wt=json&fq=productName:("fusion")&fq=productVersion:("5.10")&tags=howto-homepage-default&start=0&rows=10',
        icon: 'how-to'
      }
    ]
  },
  { 
    name: 'Smart Answers',
    link: '/fusion/$version/461/smart-answers',
    alternateLink: '/fusion/5.4/461/smart-answers/',
    tag: SMART_ANSWERS_TAG,
    icon: '/assets/icons/category-queryContent.svg',
    versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
    subMenu: 'Fusion 5',
  },
  {
    name: 'Predictive Merchandiser',
    link: '/fusion/$version/591/predictive-merchandiser',
    alternateLink: '/fusion/5.4/591/predictive-merchandiser/',
    description: 'How to craft a custom search experience with Predictive Merchandiser.',
    tag: PREDICTIVE_MERCHANDISER_TAG,
    icon: '/assets/icons/category-analyzeData.svg',
    versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
    subMenu: 'Fusion 5',
  },
  {
    name: 'Experience Optimizer',
    link: '/fusion/$version/62cqt4/experience-optimizer/',
    alternateLink: '/fusion/5.4/62cqt4/experience-optimizer/',
    tag: EXPERIENCE_OPTIMIZER_TAG,
    icon: '/assets/icons/capability-EO.svg',
    versions: ['5.4'],
    subMenu: 'Fusion 5'
  },
  {
    name: 'App Studio',
    link: '/fusion/$version/963/fusion-app-studio',
    alternateLink: '/fusion/5.4/963/fusion-app-studio',
    tag: APP_STUDIO_TAG,
    icon: '/assets/icons/product-AppStudio.svg',
    versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
    subMenu: 'Fusion 5',
  },
  {
    name: 'Fusion Connectors',
    link: '/fusion-connectors/$version/2/fusion-connectors',
    alternateLink: '/fusion-connectors/5.10/2/fusion-connectors',
    description: 'Our comprehensive connector ecosystem enables effortless ingestion of data from diverse sources. Fusion Connectors feature automatic updates, robust security measures, and superior scalability through distributed fetching.',
    tag: FUSION_CONNECTORS_TAG,
    icon: '/assets/icons/category-personalizeApps.svg',
    versions: allVersions,
    searchable: true,
    isHomeGrid: true,
    personas: fusionPersonas,
    objectives: fusionObjectives,
    highlight: [
      { 
        title: 'Java Connector Development',
        link: '/fusion/5.10/hpbgki/java-connector-development?ref=homepage',
        icon: 'dev'
      },
      {
        title: 'Connectors SDK Javadocs',
        link: '/fusion/5.10/10917/connectors-sdk-javadocs?ref=homepage',
        icon: 'connector'
      }
    ]
  },
  {
    name: 'Fusion Server',
    link: '/fusion-server/$version/8764/fusion-server',
    alternateLink: '/fusion-server/4.1/8764/fusion-server/',
    description: 'Built on the proven foundation of Solr and Spark, Fusion 4 is a highly scalable search platform that indexes and stores data for real-time discovery, empowering developers to build sophisticated applications for fast and accurate information retrieval.',
    tag: FUSION_SERVER_TAG,
    icon: '/assets/icons/product-Server.svg',
    versions: ['4.0', '4.1'],
    searchable: true,
    isHomeGrid: true,
    subMenu: 'Fusion 4',
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
  { 
    name: 'Fusion AI',
    link: '/fusion-ai/$version/428/fusion-ai',
    alternateLink: '/fusion-ai/4.1/428/fusion-ai/',
    description: 'How to add intelligence to your search applications.',
    tag: FUSION_AI_TAG,
    icon: '/assets/icons/product-AI.svg',
    versions: ['4.0', '4.1'],
    searchable: true,
    subMenu: 'Fusion 4',
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
  {
    name: 'App Studio',
    link: '/app-studio/$version/963/fusion-app-studio',
    alternateLink: '/app-studio/4.1/963/fusion-app-studio/',
    description: 'How to develop Fusion-powered search applications with App Studio.',
    tag: APP_STUDIO_TAG,
    icon: '/assets/icons/product-AppStudio.svg',
    versions: ['4.0', '4.1'],
    searchable: true,
    subMenu: 'Fusion 4',
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
  {
    name: 'Other Products',
    link: '/other-products/8816/legacy-products',
    tag: OTHER_PRODUCTS_TAG,
    alternateLink: '/other-products/8816/legacy-products',
    description: 'Documentation for other legacy products, such as Fusion 2 and 3, Lucidworks Cloud, and Lucidworks Search.',
    isHomeGrid: true,
    icon: '/assets/icons/product-noLogo.svg',
  }
];

const productItemsPretty = {
  'fusion': 'Fusion',
  'fusion-server': 'Fusion Server',
  'fusion-ai': 'Fusion AI',
  'fusion-connectors': 'Fusion Connectors',
  'app-studio': 'App Studio'
}

const capabilitiesData = {
  'Fusion 5': [
    {
      name: 'Smart Answers',
      link: '/fusion/$version/461/smart-answers',
      description: 'Versatile, scalable Semantic Search platform.',
      versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
      icon: '/assets/icons/capability-smart-answers-icon.svg'
    },
    {
      name: 'Predictive Merchandiser',
      link: '/fusion/$version/591/predictive-merchandiser',
      description: 'Curate a custom shopping experience.',
      versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
      icon: '/assets/icons/capability-predictive-icon.svg',
    },
    {
      name: 'Experience Optimizer',
      link: '/fusion/$version/62cqt4/experience-optimizer',
      description: 'Visually create rules for search and category pages.',
      versions: ['5.4'],
      icon: '/assets/icons/capability-EO.svg',
    },
    {
      name: 'App Studio',
      link: '/fusion/$version/963/fusion-app-studio',
      description:  'Rapid development for all your search apps.',
      versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
      icon: '/assets/icons/capabilities-app-studio.svg'
    },
    {
      name: 'Fusion Connectors',
      link: '/fusion-connectors/$version/2/fusion-connectors',
      description: 'Get your data into Fusion fast.',
      versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
      icon: '/assets/icons/capabilities-fusion-connectors.svg',
    }
  ],
  'Fusion 4': [
    {
      name: 'Fusion Server',
      link: '/fusion-server/$version/8764/fusion-server',
      description: "Lucidworks' core product: powerful, scalable search, on premises.",
      icon: '/assets/icons/capabilities-fusion-server.svg',
      versions: ['4.0', '4.1']
    },
    {
      name: 'Fusion AI',
      link: '/fusion-ai/$version/428/fusion-ai',
      description: 'Recommenders, machine learning, and classifiers for superior relevancy.',
      icon: '/assets/icons/capabilities-fusion-ai.svg',
      versions: ['4.0', '4.1']
    },
    {
      name: 'App Studio',
      link: '/app-studio/$version/963/fusion-app-studio',
      description: 'Rapid development for all your search apps.',
      icon: '/assets/icons/capabilities-app-studio.svg',
      versions: ['4.0', '4.1']
    },
    {
      name: 'Fusion Connectors',
      link: '/fusion-connectors/$version/2/fusion-connectors',
      description: 'Get your data into Fusion fast.',
      icon: '/assets/icons/capabilities-fusion-connectors.svg',
      versions: ['4.0', '4.1']
    }
  ],
  'Fusion Connectors 5': [
    {
      name: 'Fusion 5',
      link: '/fusion/$version/6764/fusion',
      description: 'Easily deploy AI-powered data discovery and search applications.',
      versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
      icon: '/assets/icons/capabilities-fusion-5.svg'
    },
    {
      name: 'Connectors SDK',
      link: '/fusion/$version/15/connectors-sdk',
      description: 'Connectors SDK...',
      icon: '/assets/icons/capabilities-fusion-connectors.svg',
      versions: ['5.1', '5.2', '5.3', '5.4', '5.6', '5.7', '5.8', '5.10'],
    }
  ],
  'Fusion Connectors 4': [
    {
      name: 'Fusion 4',
      link: '/fusion-server/$version/8764/fusion-server',
      description: 'Index and store data for real-time discovery.',
      icon: '/assets/icons/capabilities-fusion-4.svg',
      versions: ['4.0', '4.1']
    }
  ]
}

const findProductItemFromBasePath = (basePath) => {
  return productItems.find(element => {
    const elementLink = element.link;
    const elementSliceIndex = elementLink.indexOf('/', 1);
    const elementProduct = elementSliceIndex > 0 ? elementLink.slice(1, elementSliceIndex) : elementLink;
    return elementProduct === basePath
  })
}

const isValidProductVersion = (frontmatter,  version = 0) => {

  const skipBefore = frontmatter && frontmatter.skipBefore ? frontmatter.skipBefore : '1.0'
  const skipAfter = frontmatter && frontmatter.skipAfter ? frontmatter.skipAfter : '9.9'

  let isValid = false
  if(version === 'connected-search'){
    isValid = true
  } else {
      isValid = semverGte(`${skipAfter}.0`, `${version}.0`) && semverGte(`${version}.0`,`${skipBefore}.0`);
  }

  return isValid
}

const findGlobalProduct = (basePath, version, productList) => {
  const foundProductObj = findProductItemFromBasePath(basePath)
  if(foundProductObj === undefined){
    return null;
  }
  let found;

  for(let i = 0; i < productList.length; i++){
    if(productList[i]?.options){
      for(let k = 0; k < productList[i].options.length; k++){
        const sub = productList[i].options[k]
        if((sub.value.split('.')[0] === foundProductObj.subMenu && sub.label === version) || (sub.tag === basePath && (sub.label === version || sub.label.replace(' ', '-') === version))){
          found = sub
          break;
        }
      }
    } else if (!found && productList[i].tag === basePath){
        found = productList[i]
      break;
    }
    if(found !== undefined) break
  }

  return found;
}


// Helper funciton for 'productDropdownItems' to generate proper sidebar naming for Fusion 4 (Fusion Server 4.x -> Fusion 4.x), and all product landing page links
const getVersionOptions = (name, versionsList, link, tag) => {
  if (name === 'Springboard'){
    return versionsList.map(ver => ({label: ver.replace('-', ' '), value: ver.replace('-', ' '), link: link.replace('$version', ver), tag}))
  }
  return versionsList.map(ver => ({label: ver, value: `${name.replace(' Server', '')} ${ver}`, link: link.replace('$version', ver), tag}))
}

const visibleProducts = productItems.filter(prod => !prod.hidden)

// Use productItems to generate nested product/version selector values for sidebar
const productDropdownItems = visibleProducts.reduce((optionsArray, prod) => { 
  const isInOptionsArray = optionsArray.some(obj => (obj.label === prod.subMenu))
  if(!isInOptionsArray){
    if(prod.subMenu) return ([...optionsArray, {label: prod.subMenu, options: getVersionOptions(prod.name, prod.versions, prod.link, prod.tag).reverse(), tag: prod.tag}]);
    if(prod.versions) return ([...optionsArray, {label: prod.name, options: getVersionOptions(prod.name, prod.versions, prod.link, prod.tag).reverse(), tag: prod.tag}]);
    if(prod.apptypes) return ([...optionsArray, {label: prod.name, options: getVersionOptions(prod.name, prod.apptypes, prod.link, prod.tag).reverse(), tag: prod.tag}]);
    return [...optionsArray, {label: prod.name, value: prod.name, link: prod.link, tag: prod.tag }]
  } 
  return optionsArray;
}, [])


module.exports = {
  FUSION_TAG,
  SMART_ANSWERS_TAG,
  PREDICTIVE_MERCHANDISER_TAG,
  EXPERIENCE_OPTIMIZER_TAG,
  APP_STUDIO_TAG,
  FUSION_SERVER_TAG,
  FUSION_AI_TAG,
  FUSION_CONNECTORS_TAG,
  OTHER_PRODUCTS_TAG,
  allVersions,
  productItems,
  productItemsPretty,
  fusion4Tags,
  capabilitiesData,
  productDropdownItems,
  isValidProductVersion,
  findProductItemFromBasePath,
  findGlobalProduct
}
