import React, { useReducer } from 'react';

export const SearchContext = React.createContext();
export const SearchDispatchContext = React.createContext();

export const SET_DEFAULT_SEARCH = 'DEFAULT_SEARCH'

const initialSearchState = {
  defaultSearch: '',
};

function reducer(state, action) {
  switch (action.type) {
    case SET_DEFAULT_SEARCH: {
      return {
        ...state,
        defaultSearch: action.payload,
      };
    }
    default:
      throw new Error('Bad Action Type');
  }
}

// eslint-disable-next-line react/prop-types
const SearchContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialSearchState);
  return (
    <SearchDispatchContext.Provider value={dispatch}>
      <SearchContext.Provider value={state}>{children}</SearchContext.Provider>
    </SearchDispatchContext.Provider>
  );
};

export default SearchContextProvider;
