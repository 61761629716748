const fusionPersonas = [
  {
    name: 'Merchandisers',
    tag: 'merchandiser',
  },
  {
    name: 'Search engineers',
    tag: 'search-engineer',
  },
  {
    name: 'UI engineers',
    tag: 'ui-engineer',
  },
  {
    name: 'Data scientists',
    tag: 'data-scientist',
  },
  {
    name: 'Dev Ops',
    tag: 'devops',
  }
]


const personaPretty = {
  'merchandiser': 'Merchandisers',
  'search-engineer': 'Search engineers',
  'ui-engineer': 'UI engineers',
  'data-scientist': 'Data scientists',
  'devops': 'Dev Ops',
  'admin': 'Administrator',
  'backend-dev': 'Backend developer',
  'frontend-dev': 'Frontend developer'
}

module.exports = {
  fusionPersonas,
  personaPretty
}
